export const classes = [
  { Dubai: 'horizontal-wrapper' },
  { London: 'only-body' },
  { Seoul: 'horizontal-wrapper modern-type' },
  { LosAngeles: 'horizontal-wrapper material-type' },
  { Paris: 'horizontal-wrapper dark-sidebar' },
  { Tokyo: 'compact-sidebar' },
  { Madrid: 'horizontal-wrapper color-sidebar' },
  { Moscow: 'compact-sidebar compact-small' },
  { NewYork: 'horizontal-wrapper box-layout' },
  { Singapore: 'horizontal-wrapper enterprice-type' },
  { Rome: 'compact-sidebar compact-small material-icon' },
  { Barcelona: 'horizontal-wrapper enterprice-type advance-layout' }
];