import * as types from "./types";
import * as API_URL from "../../../env";

export const login = (payload) => ({
    type: types.LOGIN,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/public/blank-password-login",
        method: "POST",
        body: payload
    },
});

export const logout = () => ({
    type: types.LOGOUT,
});

export const authenticated = () => ({
    type: types.AUTHENTICATED,
});

export const initializeSession = (payload) => ({
    type: types.INITIALIZE,
    payload
});

export const destroySession = () => ({
    type: types.DESTROY
});

export const fetchListMenusForRole = (payload) => ({
    type: types.FETCH_MENU_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/role/list-portal-menus-for-role",
        method: "POST",
        body: payload
    },
});

export const ChangePassword = (payload) => ({
    type: types.CHANGE_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/public/change-password",
        method: "POST",
        body: payload
    },
});

export const userResetPassword = (payload) => ({
    type: types.RESET_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL + "/user/user-reset-password",
        method: "POST",
        body: payload
    },
});

export const forgotPassword = (payload) => ({
    type: types.CHANGE_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/public/forgot-password",
        method: "POST",
        body: payload
    },
});

export const resetPassword = (payload) => ({
    type: types.CHANGE_PASSWORD,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/public/reset-password",
        method: "POST",
        body: payload
    },
});