import React, { Fragment } from 'react'
import {
  Container,
  CardBody,
  Input,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import * as API_URL from '../../env.js'
import request from '../../utils/request'
import { toast } from 'react-toastify'
import { Submit } from '../../Constant'
import { connect } from 'react-redux'
import { myDetailsOperations } from '../../state/ducks/myDetails'
import GoogleFontLoader from 'react-google-font-loader'
import '../Menu.css'

class MyDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      BasicTab: '1',
      selectedUserData: {},
      selectedMenu: '',
    }
  }
  async componentDidMount() {
    try {
      const { loggedInUserId } = this.props
      if (!loggedInUserId) {
        console.log('No user ID found')
        return
      }
      const options = {
        url:
          API_URL.BACKEND_SERVICE_API_URL +
          `/user/get-user-data/${loggedInUserId}`,
        method: 'GET',
        headers: {
          authorization: localStorage.getItem('token'),
        },
      }
      let response = await request(options)
      this.setState({ selectedUserData: response.payload })
    } catch (err) {
      console.log(err)
    }
  }

  handleInput = async (e) => {
    try {
      let tid = e.target.id
      const { selectedUserData } = this.state
      switch (tid) {
        case 'fname':
          const first_name = e.target.value
          this.setState({
            selectedUserData: {
              ...selectedUserData,
              first_name,
            },
          })
          break
        case 'lname':
          const last_name = e.target.value
          this.setState({
            selectedUserData: {
              ...selectedUserData,
              last_name,
            },
          })
          break
        case 'email':
          const user_email = e.target.value
          this.setState({
            selectedUserData: {
              ...selectedUserData,
              user_email,
            },
          })
          break
        default:
          console.log('No Matching target found in handleInput')
          break
      }
    } catch (err) {
      console.log(err)
    }
  }

  onUpdate = async (e) => {
    e.preventDefault()
    try {
      const { selectedUserData } = this.state
      let updateResponse = await this.props.updateMyDetails({
        id: selectedUserData.user_id,
        first_name: selectedUserData.first_name,
        last_name: selectedUserData.last_name,
        user_email: selectedUserData.user_email,
      })
      toast.success(updateResponse.message, { autoClose: 10000 })
      window.open('/my-details/', '_self')
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 })
      console.log(err)
    }
  }

  handleMenuClick = (menu) => {
    this.setState({ selectedMenu: menu })
  }

  onBtnClick = () => {
    window.open('/my-stories/', '_self')
  }

  render() {
    const { selectedMenu, selectedUserData } = this.state
    const ulStyle = {
      display: 'flex',
      color: '#a186be',
      listStyle: 'none',
      padding: 0,
      textAlign: 'center',
      justifyContent: 'center',
      fontWeight: '500',
      fontFamily: 'Fira Sans',
      fontSize: '.8rem',
    }

    const spanStyle = {
      background: '#fdc1ea',
      display: 'inline-block',
      height: '20px',
      width: '1px',
      margin: '0 20px',
    }

    const liStyle = {
      cursor: 'pointer',
      paddingBottom: '5px', // Add some padding to make the border more visible
    }
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form onSubmit={this.onUpdate}>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: 'center',
                    font: 'normal normal 300 2.2rem/1.4 Martel',
                    color: '#000',
                  }}
                >
                  My Account
                </h2>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #FEC1EB',
                      height: '1px',
                      width: '102px',
                      display: 'inline-block',
                    }}
                  ></span>
                </div>
                <ul className="mt-4 myAccountMenu" style={ulStyle}>
                  <li
                    style={
                      selectedMenu === 'billing' ? { ...liStyle } : liStyle
                    }
                    onClick={() => this.handleMenuClick('billing')}
                  >
                    <Link
                      to="/billing/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      BILLING
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    className="current-item"
                    style={
                      selectedMenu === 'my-details' ? { ...liStyle } : liStyle
                    }
                    onClick={() => this.handleMenuClick('my-details')}
                  >
                    <Link
                      to="/my-details/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      MY DETAILS
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={selectedMenu === 'emails' ? { ...liStyle } : liStyle}
                    onClick={() => this.handleMenuClick('emails')}
                  >
                    <Link
                      to="/emails/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      EMAILS
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={
                      selectedMenu === 'reset-password'
                        ? { ...liStyle }
                        : liStyle
                    }
                    onClick={() => this.handleMenuClick('reset-password')}
                  >
                    <Link
                      to="/reset-password/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      RESET PASSWORD
                    </Link>
                  </li>
                </ul>
              </Row>
              <Row>
                {/* <Col md='2'></Col> */}
                <Col md="12" style={{ maxWidth: '950px', margin: '0 auto' }}>
                  <div
                    className="billingMyStoryTitle"
                    style={{
                      fontSize: '17px',
                      fontWeight: '400',
                      fontFamily: 'Martel',
                      borderBottom: '2px solid #fdc1ea',
                      paddingBottom: '12px',
                      marginBottom: '30px',
                    }}
                  >
                    My Details
                  </div>
                </Col>
                {/* <Col md='2'></Col>
                <Col md='2'></Col> */}
                <Col
                  md="12"
                  style={{
                    maxWidth: '950px',
                    margin: '0 auto',
                    padding: '0 15px',
                  }}
                >
                  <Row style={{ margin: '0' }}>
                    <Col
                      xs={12}
                      style={{ background: '#EEE', padding: '2% 2% 0% 4%' }}
                    >
                      <FormGroup className="mb-3 mt-3">
                        <Label
                          style={{ fontWeight: '400', fontFamily: 'Martel' }}
                        >
                          First Name
                        </Label>
                        <Input
                          type="text"
                          style={{ fontWeight: '400', fontFamily: 'Fira Sans' }}
                          name="fname"
                          id="fname"
                          placeholder="Enter First Name"
                          value={selectedUserData.first_name || ''}
                          onChange={this.handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      xs={12}
                      style={{ background: '#EEE', padding: '0% 2% 0% 4%' }}
                    >
                      <FormGroup className="mb-3">
                        <Label
                          style={{ fontWeight: '400', fontFamily: 'Martel' }}
                        >
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          style={{ fontWeight: '400', fontFamily: 'Fira Sans' }}
                          name="lname"
                          id="lname"
                          placeholder="Enter Last Name"
                          value={selectedUserData.last_name || ''}
                          onChange={this.handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      xs={12}
                      style={{ background: '#EEE', padding: '0% 2% 3% 4%' }}
                    >
                      <FormGroup className="mb-3">
                        <Label
                          style={{ fontWeight: '400', fontFamily: 'Martel' }}
                        >
                          Email Address
                        </Label>
                        <Input
                          type="text"
                          style={{ fontWeight: '400', fontFamily: 'Fira Sans' }}
                          name="email"
                          id="email"
                          placeholder="Enter Email Address"
                          value={selectedUserData.user_email || ''}
                          onChange={this.handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    {/* <Col xs={5}></Col> */}
                    <Col xs={12} style={{ textAlign: '0 auto' }}>
                      <button
                        type="submit"
                        className="custom-button"
                        style={{ margin: '0 auto' }}
                      >
                        {Submit}
                      </button>
                    </Col>
                    <Col xs={5}></Col>
                  </Row>
                </Col>
                {/* <Col md='2'></Col> */}
              </Row>
              <Row className="mt-2">
                <span
                  style={{
                    borderBottom: '1px solid #ccc',
                    height: '2px',
                    width: '880px',
                    margin: '10px auto',
                  }}
                ></span>
              </Row>
              <Row className="mt-1">
                {/* <Col xs={5}></Col> */}
                <Col xs={12} style={{ textAlign: 'center' }}>
                  <button
                    className="custom-button"
                    style={{ margin: '0 auto' }}
                    type="submit"
                    onClick={this.onBtnClick}
                  >
                    {'View my stories '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="feather feather-chevron-right"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <polyline points="9 18 15 12 9 6" />
                    </svg>
                  </button>
                </Col>
                {/* <Col xs={4}></Col> */}
              </Row>
              <Row className="mt-4 mb-5">
                <ul style={ulStyle}>
                  <li style={liStyle}>
                    <Link to="https://gys.coalescets.com/">BUY A STORY</Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li style={liStyle}>
                    <Link to="https://gys.coalescets.com">GIFT A STORY</Link>
                  </li>
                </ul>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id }
}

const mapDispatchToProps = {
  updateMyDetails: myDetailsOperations.updateMyDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDetails)
