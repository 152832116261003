import React, { Fragment } from 'react'
import { Container, Col, Row, Label, Input } from 'reactstrap'
import html2pdf from 'html2pdf.js'
import GoogleFontLoader from 'react-google-font-loader'
import { myStoryOperations } from '../../state/ducks/story'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { toast, ToastContainer } from 'react-toastify'
import '../Button.css'
import * as API_URL from '../../env.js'

class Pdf extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: 'option1',
      selectedQuestionData: '',
      showPdfContent: false,
      email: '', // Add email to state
      message: 'Please find my question PDF attached.',
      loading: false,
      text: '',
      content: '',
    }
  }

  async componentDidMount() {
    const questionId = localStorage.getItem('selectedQuestionId')
    this.setState({
      selectedQuestionData: questionId
    })
    // console.log("questionId===>>>>",questionId);
    /*const response = await this.props.listQuestionDetailsWithImageToBase64({ id: questionId })
    const questionData = response.payload
    // console.log('questions===>>>', questionData)
    this.setState({
      selectedQuestionData: questionData,
      text: questionData.answer,
      content: questionData.question,
    })*/
  }

  handleOptionChange = (e) => {
    const selectedOption = e.target.value
    this.setState({ selectedOption })
  }

  handleInputChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  generatePDF = () => {
    const { selectedOption, selectedQuestionData } = this.state
    if (selectedOption === 'option2') {
      this.sendPDFToEmail();
    }
    else {
      let fileURL = `${API_URL.API_URL + "/pdf/" + selectedQuestionData + ".pdf"}`;
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    }
    // this.setState({ loading: true })
    // const { selectedOption, content } = this.state

    // if (selectedOption === 'option2') {
    //   this.sendPDFToEmail()
    // } else {
    //   // Show the content before generating the PDF
    //   this.setState({ showPdfContent: true }, () => {
    //     const element = document.getElementById('pdf-content')
    //     if (!element) {
    //       console.error('Element with id "pdf-content" not found.')
    //       this.setState({ loading: false })
    //       return
    //     }
    //     // Configure options for html2pdf with A5 page size
    //     const options = {
    //       margin: [40, 25, 10, 25], // Margins: [top, left, bottom, right]
    //       filename: `document_${new Date()
    //         .toISOString()
    //         .replace(/[-T:\.Z]/g, '')}.pdf`,
    //       image: { type: 'jpeg', quality: 0.98 },
    //       html2canvas: { scale: 2, useCORS: true }, // High resolution
    //       jsPDF: { unit: 'mm', format: 'a5', orientation: 'portrait' }, // A5 size in mm
    //       pagebreak: {
    //         mode: ['avoid-all', 'css'], // Avoid breaking paragraphs and images
    //         after: ['img', 'p'], // Ensure images and paragraphs start on a new page if they don’t fit
    //         before: 'img',
    //         avoid: ['p', 'img'],
    //       },
    //     }

    //     html2pdf()
    //       .from(element)
    //       .set(options)
    //       .toPdf()
    //       .get('pdf')
    //       .then((pdf) => {
    //         const totalPages = pdf.internal.getNumberOfPages()
    //         const pageWidth = pdf.internal.pageSize.getWidth()
    //         const pageHeight = pdf.internal.pageSize.getHeight()

    //         // Margins
    //         const leftMargin = 20 // Left margin
    //         const rightMargin = 15 // Right margin
    //         const topMargin = 15 // Top margin (below header)
    //         const bottomMargin = 20 // Bottom margin

    //         // Header and Footer Heights
    //         const headerHeight = 10 // Height of the header
    //         const footerHeight = 10 // Height of the footer
    //         const contentVerticalOffset = topMargin + headerHeight // Offset for content below header

    //         // Font settings
    //         const fontSize = 18 // Font size for content
    //         const underlineWidth = 0.5 // Thickness for underline

    //         pdf.setFont('Martel')

    //         // Center content on the first page
    //         pdf.setPage(1)
    //         const contentWidth = pageWidth - leftMargin - rightMargin

    //         function wrapText(text, maxWidth) {
    //           let lines = []
    //           let line = ''
    //           const words = text.split(' ')

    //           for (let i = 0; i < words.length; i++) {
    //             const testLine = line + words[i] + ' '
    //             const testWidth = pdf.getTextWidth(testLine)

    //             if (testWidth > maxWidth && i > 0) {
    //               lines.push(line)
    //               line = words[i] + ' '
    //             } else {
    //               line = testLine
    //             }
    //           }

    //           lines.push(line) // Add the last line
    //           return lines
    //         }

    //         const lines = wrapText(content, contentWidth)
    //         const lineHeight = fontSize * 1.2 // Adjust line height based on font size

    //         // Add the content on the first page, centered
    //         let y = contentVerticalOffset

    //         lines.forEach((line, index) => {
    //           const textWidth = pdf.getTextWidth(line)
    //           const x = leftMargin + (contentWidth - textWidth) / 2 // Center text horizontally within margins

    //           // Add text line
    //           pdf.text(line, x, y)
    //           y += lineHeight // Move to the next line
    //         })

    //         // Underline the centered content
    //         lines.forEach((line, index) => {
    //           const textWidth = pdf.getTextWidth(line)
    //           const x = leftMargin + (contentWidth - textWidth) / 2
    //           const underlineY = contentVerticalOffset + index * lineHeight + 2

    //           pdf.setLineWidth(underlineWidth)
    //           pdf.line(x, underlineY, x + textWidth, underlineY) // Draw underline
    //         })

    //         // Add header and footer to each page
    //         for (let i = 1; i <= totalPages; i++) {
    //           pdf.setPage(i)

    //           // Add header on every page
    //           pdf.setFontSize(10)
    //           pdf.text('', leftMargin, headerHeight) // Customize header

    //           // Add footer with page number on every page
    //           pdf.setFontSize(10)
    //           pdf.text(
    //             `page ${i} `,
    //             pageWidth / 2,
    //             pageHeight - footerHeight / 2,
    //             { align: 'center' }
    //           )

    //           // Remove content from pages other than the first page
    //           if (i > 1) {
    //             pdf.text('', leftMargin, contentVerticalOffset) // No content on other pages
    //           }
    //         }

    //         // Open the PDF in a new tab instead of downloading it
    //         const pdfUrl = pdf.output('bloburl')
    //         window.open(pdfUrl) // Open in a new tab
    //       })
    //       .then(() => {
    //         this.setState({ showPdfContent: false, loading: false })
    //       })
    //       .catch((error) => {
    //         console.error('Error generating PDF:', error)
    //         this.setState({ showPdfContent: false, loading: false })
    //       })
    //   })
    // }
  }

  // generatePDF = async () => {
  //   const { selectedQuestionData } = this.state

  //   const doc = new jsPDF({
  //     orientation: 'portrait',
  //     unit: 'mm',
  //     format: 'a5',
  //     putOnlyUsedFonts: true,
  //     floatPrecision: 16,
  //   })

  //   // Create a temporary div to hold the content for PDF generation
  //   const contentDiv = document.createElement('div')
  //   contentDiv.style.width = '148mm' // A5 size width
  //   contentDiv.style.padding = '10mm'
  //   contentDiv.style.fontSize = '12px'
  //   contentDiv.style.lineHeight = '1.5'
  //   contentDiv.innerHTML = `
  //     <h2 style="text-align: center;">${selectedQuestionData.question}</h2>
  //     ${selectedQuestionData.answer}
  //   `
  //   document.body.appendChild(contentDiv)

  //   // Capture the content with html2canvas
  //   html2canvas(contentDiv, {
  //     useCORS: true,
  //     scale: 2, // Increase the scale for higher quality
  //   })
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png')
  //       const imgWidth = 148 // A5 width in mm
  //       const pageHeight = 210 // A5 height in mm
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width
  //       let heightLeft = imgHeight
  //       let position = 0

  //       // Add header and footer
  //       const addHeaderFooter = (pageNum) => {
  //         doc.setFontSize(10)
  //         doc.text('Header: PDF Generated', 10, 10) // Add header text
  //         doc.text(
  //           `Page ${pageNum}`,
  //           doc.internal.pageSize.width - 20,
  //           doc.internal.pageSize.height - 10
  //         ) // Footer with page number
  //       }

  //       let pageNum = 1
  //       // Add the first page
  //       doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
  //       addHeaderFooter(pageNum)
  //       heightLeft -= pageHeight

  //       // Loop to add additional pages if content exceeds page size
  //       while (heightLeft > 0) {
  //         pageNum++
  //         doc.addPage()
  //         position = heightLeft - imgHeight
  //         doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
  //         addHeaderFooter(pageNum)
  //         heightLeft -= pageHeight
  //       }

  //       // Open the generated PDF in a new window
  //       const pdfOutput = doc.output('bloburl')
  //       window.open(pdfOutput)

  //       // Clean up the temporary div
  //       document.body.removeChild(contentDiv)
  //     })
  //     .catch((err) => {
  //       console.error('Error rendering content for PDF:', err)
  //       document.body.removeChild(contentDiv)
  //     })
  // }

  sendPDFToEmail = async () => {
    this.setState({ loading: true })
    try {
      const { selectedQuestionData, email, message } = this.state

      const payload = {
        id: selectedQuestionData,
        email,
        message,
      }
      // console.log("seleQuestion data====",selectedQuestionData);
      const response = await this.props.sendPDF(payload)
      toast.success(response.message, { autoClose: 10000 })
    } catch (error) {
      console.error('Error sending invitation:', error.response.data.message)
      toast.error(error.response.data.message, {
        autoClose: 10000,
      })
    } finally {
      this.setState({ loading: false }) // Stop loading
    }
  }

  render() {
    const { selectedOption, text, content, showPdfContent, email, message } =
      this.state
    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <ToastContainer />
          <Row>
            <h2
              className="mt-5"
              style={{
                textAlign: 'center',
                font: 'normal normal 300 2.2rem/1.4 Martel',
                color: '#000',
              }}
            >
              Preview your PDF or share it with
            </h2>
            <h2
              style={{
                textAlign: 'center',
                font: 'normal normal 300 2.2rem/1.4 Martel',
                color: '#000',
              }}
            >
              the email of your choice
            </h2>
            <div style={{ textAlign: 'center' }}>
              <span
                style={{
                  borderBottom: '1px solid #FEC1EB',
                  height: '1px',
                  width: '102px',
                  display: 'inline-block',
                }}
              ></span>
            </div>
          </Row>
          <span
            style={{
              borderBottom: '2px solid #FEC1EB',
              height: '2px',
              width: '102px',
              margin: '20px auto',
            }}
          ></span>
          <Row>
            <Col sm="3"></Col>
            <Col sm="8">
              <div>
                <label
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Fira Sans',
                    fontWeight: '300',
                  }}
                >
                  <input
                    type="radio"
                    value="option1"
                    checked={selectedOption === 'option1'}
                    onChange={this.handleOptionChange}
                  />
                  &nbsp;&nbsp;Preview PDF
                </label>
                <br></br>
                <label
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Fira Sans',
                    fontWeight: '300',
                  }}
                >
                  <input
                    type="radio"
                    value="option2"
                    checked={selectedOption === 'option2'}
                    onChange={this.handleOptionChange}
                  />
                  &nbsp;&nbsp;Send PDF to an email address
                </label>
              </div>
            </Col>
            <Col sm="1"></Col>
          </Row>
          {selectedOption === 'option2' && (
            <Row className="mt-1">
              <Col sm="3"></Col>
              <Col sm="7">
                <span>
                  <Label
                    style={{
                      fontSize: '14px',
                      fontWeight: '700',
                      fontFamily: 'Fira Sans',
                    }}
                  >
                    Please enter an Email address&nbsp;
                    <span
                      style={{
                        color: '#c02b0a',
                        fontSize: '12px',
                        fontWeight: '400',
                        fontStyle: 'italic',
                      }}
                    >
                      (Required)
                    </span>
                  </Label>
                  <Input
                    type="text"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      border: '1px solid #767676',
                      fontFamily: 'Fira Sans',
                    }}
                    name="email"
                    id="email"
                    value={email}
                    onChange={this.handleInputChange}
                  />
                  <Label
                    style={{
                      fontSize: '14px',
                      fontWeight: '700',
                      fontFamily: 'Fira Sans',
                    }}
                    className="mt-3"
                  >
                    Email message
                  </Label>
                  <Input
                    type="textarea"
                    style={{
                      height: '288px',
                      fontSize: '14px',
                      fontWeight: '400',
                      border: '1px solid #767676',
                      fontFamily: 'Fira Sans',
                    }}
                    name="message"
                    id="message"
                    value={message}
                    onChange={this.handleInputChange}
                  />
                </span>
              </Col>
            </Row>
          )}
          <Row className="mt-3 mb-5">
            <Col sm="3"></Col>
            <Col sm="8">
              <button
                type="button"
                className="custom-button"
                onClick={this.generatePDF}
              >
                {selectedOption === 'option2' ? 'Send PDF' : 'Generate PDF'}
              </button>
              {this.state.loading && (
                <div
                  className="loading-spinner"
                  style={{ marginLeft: '10px' }}
                />
              )}
            </Col>
            <Col sm="1"></Col>
          </Row>
          {/* <div style={{ display: 'none' }}> */}

          {/* <div id="contentDiv">
              <h2>{this.state.content}</h2>
              <p>{this.state.text}</p>
            </div>
          </div> */}
          {showPdfContent && (
            <div
              id="pdf-content"
              style={{
                padding: '20px',
                fontFamily: 'Fira Sans',
                fontSize: '5px',
                pageBreakBefore: 'always',
              }}
            >
              <div
                className="wrapped-generated"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          )}
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  listQuestionDetailsWithImageToBase64: myStoryOperations.listQuestionDetailsWithImageToBase64,
  sendPDF: myStoryOperations.sendPDF,
  generatePDF: myStoryOperations.generatePDF,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pdf)
