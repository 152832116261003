import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const authReducer = createReducer(false)({
    [types.AUTHENTICATED]: () => true,
    [types.LOGOUT]: () => false,
    [types.AUTHORIZATION_FAILED]: () => {
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        return false
    },
});

const initializeSessionReducer = createReducer([null])({
    [types.INITIALIZE]: (state, action) => ({ user: action.payload.user, token: action.payload.token }),
    [types.DESTROY]: (state, action) => null
});

const menuReducer = createReducer([])({
    [types.FETCH_MENU_LIST_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload.menus', [])
    },
    [types.RESET_PASSWORD_COMPLETED]: (state, action) => {
        const event = action.payload.payload;
        state.push(event)
        return state.slice()
    },
});

const listReducer = createReducer([])({
    [types.CHANGE_PASSWORD_COMPLETED]: (state, action) => {
        const event = action.payload.payload;
        state.push(event)
        return state.slice()
    }
});

export default combineReducers({
    session: initializeSessionReducer,
    isAuthenticated: authReducer,
    menus: menuReducer,
    list: listReducer
});