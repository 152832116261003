import React, { useState, useEffect } from 'react'
import { Container, Card, CardBody, Col, Form, Row } from 'reactstrap'

const ViewBillingData = (props) => {
  const {
    title = '',
    packageName = '',
    expiryDate = '',
    toggleDetails,
    orderId,
    timeOfReceipt = '',
    numberOfItems = '',
    discountValue = '',
    subtotal,
    total,
    index,
    selectedIndex,
    setSelectedIndex,
    // showOneDetails = false,
    setShowDetails,
    userName = '',
    email = '',
    isGifted = false,
  } = props || {}
  const [showOneDetails, setShowOneDetails] = useState(false)
  // const [selectedIndex, setSelectedIndex] = useState(false)

  // const toggleOneDetails = () => {
  //   setShowOneDetails(index === selectedIndex)
  // }

  // useEffect(() => {
  //   toggleOneDetails()
  // }, [selectedIndex])

  const toggleOneDetails = () => {
    if (index === selectedIndex) {
      setShowOneDetails(!showOneDetails) // Toggle open/close for the current card
    } else {
      setSelectedIndex(index) // Set selected index to the current item
      setShowOneDetails(true) // Ensure the new item opens its details
    }
  }

  useEffect(() => {
    if (index !== selectedIndex) {
      setShowOneDetails(false) // Close the details when another item is clicked
    }
  }, [selectedIndex])
  return (
    <Card>
      <Row className="billingMyStoryGrid">
        <Col
          xs={10}
          style={{
            background: '#EEE',
            padding: '2% 0% 3% 4%',
          }}
        >
          <div
            className="billingIteamListingTitle"
            style={{ fontSize: '30px', fontFamily: 'Martel' }}
          >
            Story:{title}
          </div>
          {userName && (
            <div
              style={{
                marginBottom: '0rem',
                marginTop: '0',
                fontSize: '17px',
                color: '#615F67',
              }}
            >
              <strong style={{ color: '#615F67', fontSize: '17px' }}>
                {'Gifted by '}
                {userName}
              </strong>
            </div>
          )}
          {email && (
            <div
              style={{
                marginBottom: '0rem',
                marginTop: '0',
                fontSize: '17px',
                color: '#615F67',
              }}
            >
              {/* <strong style={{ color: '#615F67', fontSize: '17px' }}> */}
              {email}
              {/* </strong> */}
            </div>
          )}
          <div
            style={{
              marginBottom: '1rem',
              marginTop: '0',
              fontSize: '17px',
              color: '#615F67',
            }}
          >
            {'Package '}
            <strong style={{ color: '#615F67', fontSize: '17px' }}>
              {packageName}
              {/* {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].packages[0].package : ''} */}
            </strong>
          </div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              toggleOneDetails() // Toggle details on click
            }}
          >
            {!isGifted && (
              <div
                // onClick={() => setSelectedIndex(index)}
                style={{ color: '#8A1A75', fontSize: '12px' }}
              >
                {showOneDetails
                  ? 'VIEW PACKAGE DETAILS >'
                  : 'VIEW PACKAGE DETAILS >'}
              </div>
            )}
          </a>
        </Col>
        <Col
          xs={2}
          style={{
            background: '#E0E0E0',
            textAlign: 'center',
            padding: '6% 1% 3% 1%',
          }}
        >
          <div style={{ fontSize: '15px', fontFamily: 'Martel' }}>Expiry</div>
          <div style={{ fontSize: '24px', fontFamily: 'Martel' }}>
            {expiryDate}

            {/* {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].expiryDate : ''} */}
          </div>
        </Col>
      </Row>

      {!isGifted && showOneDetails && (
        <Row>
          <Col
            xs={8}
            style={{
              background: '#8A1A75',
              fontFamily: 'Fira Sans',
              color: '#fff',
            }}
          >
            <div style={{ padding: '1%' }}>Order ID: {orderId}</div>

            {/* <div style={{ padding: "1%" }}>Order ID: {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].orderId : ''}</div> */}
            {/* <div style={{ padding: "1%" }}>Invoice Number: GYS00190</div> */}
          </Col>
          <Col xs={4} style={{ background: '#8A1A75', color: '#fff' }}>
            <div style={{ padding: '2%' }}>
              Time of receipt: {timeOfReceipt}
            </div>

            {/* <div style={{ padding: "2%" }}>Time of receipt: {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].timeOfReceipt : ''}</div> */}
            {/* <div style={{ padding: "2%" }}>Transaction ID: 4567787678987</div> */}
          </Col>
          <Col
            xs={12}
            style={{
              background: '#EEE',
              padding: '2% 0% 2% 4%',
              borderBottom: '2px solid #FEC1EB',
            }}
          >
            <div>Number of items: {numberOfItems}</div>

            {/* <div>Number of items: {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].numberOfItems : ''}</div> */}
            {/* <div>Number of printed books: 1</div> */}
          </Col>

          {!isGifted && discountValue && (
            <>
              <Col
                xs={9}
                style={{
                  background: '#EEE',
                  fontSize: '17.6px',
                  fontWeight: '200',
                  padding: '1% 4%',
                  borderBottom: '1px solid #00000026',
                }}
              >
                <div
                  style={{
                    color: '#615F67',
                    fontFamily: 'Fira Sans',
                  }}
                >
                  Discount
                </div>
              </Col>
              <Col
                xs={3}
                style={{
                  background: '#EEE',
                  fontSize: '17.6px',
                  fontWeight: 'bolder',
                  color: '#615F67',
                  padding: '1% 4%',
                  borderBottom: '1px solid #00000026',
                  textAlign: 'right',
                }}
              >
                <div>£{discountValue}</div>
              </Col>
            </>
          )}

          <Col
            xs={9}
            style={{
              background: '#EEE',
              fontSize: '17.6px',
              fontWeight: '200',
              padding: '1% 4%',
              borderBottom: '1px solid #00000026',
            }}
          >
            <div
              style={{
                color: '#615F67',
                fontFamily: 'Fira Sans',
              }}
            >
              Subtotal
            </div>
          </Col>
          <Col
            xs={3}
            style={{
              background: '#EEE',
              fontSize: '17.6px',
              fontWeight: 'bolder',
              color: '#615F67',
              padding: '1% 4%',
              borderBottom: '1px solid #00000026',
              textAlign: 'right',
            }}
          >
            {/* <div>£{parseFloat(selectedUserOrderData[0]?.subtotal).toFixed(2) || ''}</div> */}
            <div>£{parseFloat(subtotal).toFixed(2)}</div>
          </Col>

          <Col
            xs={9}
            style={{
              background: '#EEE',
              fontSize: '17.6px',
              fontWeight: '200',
              padding: '1% 4%',
              borderBottom: '',
            }}
          >
            <div
              style={{
                color: '#615F67',
                fontFamily: 'Fira Sans',
              }}
            >
              Total
            </div>
          </Col>
          <Col
            xs={3}
            style={{
              background: '#EEE',
              fontSize: '17.6px',
              fontWeight: 'bolder',
              color: '#615F67',
              padding: '1% 4%',
              borderBottom: '',
              textAlign: 'right',
            }}
          >
            {/* <div>£{parseFloat(selectedUserOrderData[0]?.total).toFixed(2) || ''}</div> */}
            <div>£{parseFloat(total).toFixed(2)}</div>
          </Col>

          <Col xs={12} style={{ background: '#EEE', padding: '1%' }}></Col>
        </Row>
      )}
    </Card>
  )
}

export default ViewBillingData
