import React from 'react';
import Routers from './Route';
import CartProvider from './_helper/Ecommerce/Cart/CardProvider';
import FilterProvider from './_helper/Ecommerce/Filter/FilterProvider';
import WishListProvider from './_helper/Ecommerce/Wishlist/WishlistProvider';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';

const App = () => (
  <div className='App'>
    <CustomizerProvider>
      <WishListProvider>
        <FilterProvider>
          <CartProvider>
            <AnimationThemeProvider>
              <Routers />
            </AnimationThemeProvider>
          </CartProvider>
        </FilterProvider>
      </WishListProvider>
    </CustomizerProvider>
  </div>
);

export default App;