import React, { Fragment, useState, useContext } from 'react'
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { H6 } from '../../../AbstractElements'
import { connect } from 'react-redux'
import logoWhite from '../../../assets/images/logo/logo.png'
import { ToastContainer, toast } from 'react-toastify'
import { Link, useParams } from 'react-router-dom'
import Header from '../../../Layout/Header'
import Footer from '../../../Layout/Footer'
import Sidebar from '../../../Layout/Sidebar'
import CustomizerContext from '../../../_helper/Customizer'
import '../../Button.css'
import { authOperations } from '../../../state/ducks/auth'

const ResetPassword = (props) => {
  const { hashkey } = useParams();
  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const { layout } = useContext(CustomizerContext)
  const { sidebarIconType } = useContext(CustomizerContext)
  const layout1 = localStorage.getItem('sidebar_layout') || layout
  const sideBarIcon = localStorage.getItem('sidebar_icon_type') || sidebarIconType

  const handleResetPassword = async (e) => {
    e.preventDefault()
    try {
      const payload = {
        hashkey: hashkey,
        newPassword: newPassword,
        confirmPassword: confirmPassword
      };
      const response = await props.resetPassword(payload)
      toast.success(response.message, { autoClose: 10000 });
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }
  const inputStyle = { border: '1px solid #ccc', background: '#fff' }
  return (
    <Fragment>
      <div
        className={`page-wrapper ${layout1}`}
        sidebar-layout={sideBarIcon}
        id="pageWrapper"
      >
        <Header isLoggedinHeader={true} />
        <div className="page-body-wrapper">
          <Sidebar isLoggedinHeader={true} />
          <div className="page-body">
            <Container fluid={true} className="p-0 login-page">
              <Row>
                <h1
                  style={{
                    textAlign: 'center',
                    marginTop: '3%',
                    font: 'normal normal 300 2.5rem/1.4 Martel',
                  }}
                >
                  Reset Password
                </h1>
                <span
                  style={{
                    borderBottom: '1px solid #FEC1EB',
                    height: '2px',
                    width: '102px',
                    margin: '0px auto',
                  }}
                ></span>
                <Col xs="12">
                  <div className="login-card" style={{ background: '#fff' }}>
                    <div
                      className="login-main"
                      style={{ background: '#F7EBF3', marginTop: '-10%' }}
                    >
                      <Link className="logo" to={process.env.PUBLIC_URL}>
                        <img
                          className="img-fluid for-light"
                          src={logoWhite}
                          width="180"
                          height="180"
                          alt="login page"
                        />
                      </Link>
                      <Form className="theme-form" onSubmit={handleResetPassword}>
                        <FormGroup>
                          <Label className="col-form-label">New Password</Label>
                          <span style={{ color: '#F61C04' }}>*</span>
                          <Input
                            className="form-control"
                            type="password"
                            style={inputStyle}
                            name="newPassword"
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="col-form-label">Confirm Password</Label>
                          <span style={{ color: '#F61C04' }}>*</span>
                          <Input
                            className="form-control"
                            type="password"
                            style={inputStyle}
                            name="confirmPassword"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                          />
                        </FormGroup>
                        <div className="position-relative form-group mb-0">\
                          <button
                            type="submit"
                            className="d-block w-100 mt-2 custom-button"
                            style={{ margin: '0 auto' }}
                          >Reset</button>
                        </div>
                        <div>
                          <H6></H6>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>
      </div>

      <ToastContainer />
    </Fragment>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  resetPassword: authOperations.resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
