import React from "react";
import { connect } from "react-redux";
import {
  Col,
  Form,
  Row,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Btn } from "../../AbstractElements";
import { FaTimes } from "react-icons/fa";
import "../Button.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { myStoryOperations } from "../../state/ducks/story";

class AddAdditionalOrderBooks extends React.Component {
  constructor(props) {
    super(props);
    // console.log('Props:', props.additionalOrderData);
    this.state = {
      showInputField: false,
      modalBookOpen: false,
      modalAdditionalBookOpen: false,
      modalAdditionalBookOpenEdit: false,
      modalOrderBookOpenEdit: false,
      selected: 1,
      shipping_id: "",
      print_color_id: 1, // initialize the state variable
      colorPrice: 0,
      copies: 1,
      shippingPrice: 0,
      subtotal: 30,
      additionalBooks: [],
      total: "",
      orderBooks: [],
      selectedAdditionalOrderData: {},
      selectedOrderBookData: {},
      isDeleteConfirm: false,
      isCancelConfirm: false,
      shipping_name:'',
      first_name:'',
      last_name:'',
      state:'',
      address:'',
      address2:'',
      city:'',
      postcode:'' 
      
    };
    

    
  }

  async componentDidMount(prevProps, prevState) {

    if (this.props.isEdit && this.props.additionalOrderData) {
     this.setState({
      ...this.props.additionalOrderData
     })

    }

  }



  componentDidUpdate(prevProps) {
    // Update formData state when additionalOrderData prop changes
    if (prevProps.additionalOrderData !== this.props.additionalOrderData) {
     
      this.setState({
        ...this.state,
      ...this.props.additionalOrderData
  
    });
  }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
     
      [name]: value
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { id, address, address2, city, copies, last_name, first_name, postcode, print_color_id, shipping_id, shipping_name, state, subtotal } = this.state;
      const updateResponse = await this.props.updateAdditionalOrderBook({
        id,
        address,
        address2,
        city,
        copies,
        first_name,
        last_name,
        postcode,
        shipping_id,
        print_color_id,
        shipping_name,
        state,
        subtotal
      });
      // Handle success response
      toast.success(updateResponse.message, { autoClose: 10000 });
      this.props.toggle(e); // Close the modal or perform any other action
    } catch (err) {
      // Handle error response
      toast.error(err.updateResponse?.data?.message || 'Failed to update', { autoClose: 10000 });
    }
  };

  onInsertAdditional = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        copies: this.state.copies,
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        postcode: this.state.postcode,
        shipping_id: this.state.shipping_id,
        print_color_id: this.state.print_color_id,
        subtotal: this.state.subtotal,
      };
      const response = await this.props.addAdditionalOrderBook(payload);
      toast.success(response.message, { autoClose: 10000 });
      window.open("/my-stories/order-books/", "_self");
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 });
      console.log(err);
    }
  };

  onUpdate = async (e) => {
  
    this.handleSubmit(e);

   
   
  };

  handleColorSelect = (option, colorPrice) => {
    const { copies, shippingPrice } = this.state;

    this.setState({
      // selected: option,
      print_color_id: option, // update print_color_id based on selection
      colorPrice: colorPrice, // update color price based on selection
      subtotal: copies * (colorPrice + shippingPrice)
    });
    // this.updateSubtotal()
  };


  handleInput = (e) => {
    const { name, value } = e.target;
    let updatedValue;
    if (value > 0) {
      updatedValue = value;
    } else {
      updatedValue = "";
    }
    const { colorPrice, shippingPrice } = this.state;
    
    this.setState({
     
      [name]: updatedValue,
      copies: updatedValue,
      subtotal: (updatedValue * (colorPrice + shippingPrice)),
    });
  };

  handleOptionChange = (event) => {
    const shippingId = Number(event.target.value);
    let shippingPrice = 0;

    // Set shipping price based on selected option
    switch (shippingId) {
      case 1:
        shippingPrice = 7;
        break;
      case 2:
        shippingPrice = 7;
        break;
      case 3:
        shippingPrice = 9;
        break;
      case 4:
        shippingPrice = 12;
        break;
      case 5:
        shippingPrice = 6;
        break;
      default:
        shippingPrice = 0;
    }
    const { copies, colorPrice } = this.state;
    this.setState((prevState)=>({
      ...prevState.colorPrice,
      shipping_id: shippingId,
      shippingPrice: shippingPrice,
      subtotal: copies * (colorPrice + shippingPrice)
    }));
  };

  onCancelConfirm = (e) => {
    if (this.state.isCancelConfirm) {
      this.props.toggle(e)
      this.setState({
        first_name:'',
        last_name:'',
        state:'',
        address:'',
        address2:'',
        city:'',
        postcode:'',
        shipping_id: '',
        shipping_name:'',
        print_color_id: 1, // initialize the state variable
        colorPrice: 0,
        copies: 1,
        subtotal: 30, 
        
    })
    } else {
      this.setState((prevState)=>({
        ...prevState,
        shipping_id: prevState.shipping_id,
        isCancelConfirm: true,
      }));
      setTimeout(() => {
        this.setState({
          ...this.state,
          isCancelConfirm: false,
        });
      }, 3000);
    }
  };

  
  onDeleteClick = async (e) => {
    e.preventDefault();
    try {
      let additionalOrderId = e.target.getAttribute("id");
      const response = await this.props.deleteAdditionalOrderBook({
        id: additionalOrderId,
      });
      toast.success(response.message, { autoClose: 10000 });
      window.open("/my-stories/order-books/", "_self");
     
    } catch (err) {
      toast.error(err.response?.data?.message, { autoClose: 10000 });
      console.log(err);
    }
  };

  onDeleteConfirm = (e) => {
    if (this.state.isDeleteConfirm) {
      this.onDeleteClick(e);
    } else {
      this.setState({
        isDeleteConfirm: true,
      });
      setTimeout(() => {
        this.setState({
          isDeleteConfirm: false,
        });
      }, 3000);
    }
  };

  render() {
    const {
      id,
      isDeleteConfirm,
      isCancelConfirm,
      subtotal,
      print_color_id,
      copies,  
      shipping_id,
      first_name,
      last_name,
      state,
      address,
      address2,
      city,
      postcode 
    } = this.state;
    const { isvisible, toggle, isEdit = false } = this.props;

    // console.log("shipping_id=====>",shipping_id)
    
    return (
      <Modal
        isOpen={isvisible}
        toggle={(e)=>toggle(e)}
        style={{ maxWidth: "900px" }}
      >
        <Form onSubmit={isEdit ? this.onUpdate : this.onInsertAdditional}>
          <ModalHeader
            style={{
              background: "#8A1A75",
              color: "#fff",
              fontSize: "1.2rem",
              fontWeight: "300",
              fontFamily: "Fira Sans",
            }}
            close={
              <FaTimes
                className="close"
                onClick={(e)=> toggle(e) }
                style={{ cursor: "pointer" }}
              />
            }
          >
            Add Additional Book
          </ModalHeader>
          <ModalBody>
            <Row style={{ padding: "2%" }}>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Fira Sans",
                }}
              >
                Would you like the inside pages of your book to be
                printed in colour?
              </div>
              <div
                style={{
                  float: "left",
                  display: "flex",
                  padding: "1% 5% 2% 6%",
                }}
              >
                <div
                  onClick={() => this.handleColorSelect(1, 30)}
                  style={{
                    border: `2px solid ${print_color_id === 1 ? "#8A1A75" : "grey"
                      }`,
                    borderRadius: "5px",
                    padding: "2%",
                    width: "45%",
                    marginRight: "2%",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      fontFamily: "Fira Sans",
                    }}
                  >
                    Black and white inner pages
                  </div>
                  <div
                    style={{
                      fontWeight: "300",
                      fontFamily: "Fira Sans",
                    }}
                  >
                    Photos on inner pages will be printed in black
                    and white
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      fontWeight: "700",
                      fontFamily: "Fira Sans",
                    }}
                  >
                    £30.00
                  </div>
                  {print_color_id === 1 && (
                    <div
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        backgroundColor: "#8A1A75",
                        color: "#fff",
                        padding: "2px 6px",
                        borderRadius: "5px",
                        fontSize: "0.8rem",
                      }}
                    >
                      Selected
                    </div>
                  )}
                </div>
                <div
                  onClick={() => this.handleColorSelect(2, 50)}
                  style={{
                    border: `2px solid ${print_color_id === 2 ? "#8A1A75" : "grey"
                      }`,
                    borderRadius: "5px",
                    padding: "2%",
                    width: "45%",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      fontFamily: "Fira Sans",
                    }}
                  >
                    Colour inner pages
                  </div>
                  <div
                    style={{
                      fontWeight: "300",
                      fontFamily: "Fira Sans",
                    }}
                  >
                    Photos on inner pages will be printed in full
                    colour
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      fontWeight: "700",
                      fontFamily: "Fira Sans",
                    }}
                  >
                    +£50.00 per book
                  </div>
                  {print_color_id === 2 && (
                    <div
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                        backgroundColor: "#8A1A75",
                        color: "#fff",
                        padding: "2px 6px",
                        borderRadius: "5px",
                        fontSize: "0.8rem",
                      }}
                    >
                      Selected
                    </div>
                  )}
                </div>
              </div>
              <div
                className="mt-3"
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Fira Sans",
                }}
              >
                How many copies?
                <span
                  style={{
                    fontStyle: "italic",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "Fira Sans",
                    color: "#c02b0a",
                  }}
                >
                  (Required)
                </span>
              </div>
              <Col md="6" className="mt-3">
                <Input
                  type="number"
                  style={{ border: "1px solid #808080" }}
                  value={copies}
                  id="number"
                  name="number"
                  onChange={this.handleInput}

                />
              </Col>
              <div
                className="mt-3"
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Fira Sans",
                }}
              >
                Name
              </div>
              <Col md="6" className="mt-3">
                <label
                  style={{
                    fontWeight: "300",
                    fontFamily: "Fira Sans",
                  }}
                >
                  First name
                </label>
                <Input
                  type="text"
                  style={{ border: "1px solid #808080" }}
                  value={first_name}
                  id="first"
                  name="first_name"
                  onChange={this.handleChange}
                  // onChange={(e) =>
                  //   this.setState({ first_name: e.target.value })
                  // }
                />
              </Col>
              <Col md="6" className="mt-3">
                <label
                  style={{
                    fontWeight: "300",
                    fontFamily: "Fira Sans",
                  }}
                >
                  Last name
                </label>
                <Input
                  type="text"
                  style={{ border: "1px solid #808080" }}
                  value={last_name}

                  id="last"
                  name="last_name"
                  onChange={this.handleChange}
                  
                />
              </Col>
              <div
                className="mt-3"
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Fira Sans",
                }}
              >
                Delivery address
              </div>
              <Col md="12" className="mt-3">
                <label
                  style={{
                    fontWeight: "300",
                    fontFamily: "Fira Sans",
                  }}
                >
                  Street Address
                </label>
                <Input
                  type="text"
                  style={{ border: "1px solid #808080" }}
                  value={address}

                  id="address"
                  name="address"
                  onChange={this.handleChange}
                 
                />
              </Col>
              <Col md="12" className="mt-3">
                <label
                  style={{
                    fontWeight: "300",
                    fontFamily: "Fira Sans",
                  }}
                >
                  Address Line 2
                </label>
                <Input
                  type="text"
                  style={{ border: "1px solid #808080" }}
                  value={address2}

                  id="address2"
                  name="address2"
                  onChange={this.handleChange}
                  
                />
              </Col>
              <Col md="6" className="mt-3">
                <label
                  style={{
                    fontWeight: "300",
                    fontFamily: "Fira Sans",
                  }}
                >
                  City
                </label>
                <Input
                  type="text"
                  style={{ border: "1px solid #808080" }}
                  value={city}

                  id="city"
                  name="city"
                  onChange={this.handleChange}
                  
                />
              </Col>
              <Col md="6" className="mt-3">
                <label
                  style={{
                    fontWeight: "300",
                    fontFamily: "Fira Sans",
                  }}
                >
                  State / Province / Region
                </label>
                <Input
                  type="text"
                  style={{ border: "1px solid #808080" }}
                  value={state}

                  id="state"
                  name="state"
                  onChange={this.handleChange}

                 
                />
              </Col>
              <Col md="6" className="mt-3">
                <label
                  style={{
                    fontWeight: "300",
                    fontFamily: "Fira Sans",
                  }}
                >
                  Postcode / ZIP
                </label>
                <Input
                  type="text"
                  style={{ border: "1px solid #808080" }}
                  value={postcode}

                  id="code"
                  name="postcode"
                  onChange={this.handleChange}

                  
                />
              </Col>
              <div
                className="mt-3"
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Fira Sans",
                }}
              >
                Shipping
                <span
                  style={{
                    fontStyle: "italic",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "Fira Sans",
                    color: "#c02b0a",
                  }}
                >
                  (Required)
                </span>
              </div>
              <label
                className="mt-3"
                style={{
                  fontWeight: "300",
                  fontFamily: "Fira Sans",
                }}
              >
                <input
                  type="radio"
                  value={1}
                  checked={shipping_id === 1}
                  onChange={this.handleOptionChange}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;UK and Europe
              </label>
              <br />
              <label
                style={{
                  fontWeight: "300",
                  fontFamily: "Fira Sans",
                }}
              >
                <input
                  type="radio"
                  value={2}
                  checked={shipping_id === 2}
                  onChange={this.handleOptionChange}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;USA
              </label>
              <br />
              <label
                style={{
                  fontWeight: "300",
                  fontFamily: "Fira Sans",
                }}
              >
                <input
                  type="radio"
                  value={3}
                  checked={shipping_id === 3}
                  onChange={this.handleOptionChange}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;Canada
              </label>
              <br />
              <label
                style={{
                  fontWeight: "300",
                  fontFamily: "Fira Sans",
                }}
              >
                <input
                  type="radio"
                  value={4}
                  checked={shipping_id === 4}
                  onChange={this.handleOptionChange}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;Israel
              </label>
              <br />
              <label
                style={{
                  fontWeight: "300",
                  fontFamily: "Fira Sans",
                }}
              >
                <input
                  type="radio"
                  value={5}
                  checked={shipping_id === 5}
                  onChange={this.handleOptionChange}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;Dubai
              </label>
              <div
                className="mt-3"
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  fontFamily: "Fira Sans",
                }}
              >
                Additional print(s) sub-total
              </div>
              <div style={{ textAlign: "right" }}>
                <span
                  style={{
                    fontWeight: "300",
                    fontFamily: "Fira Sans",
                  }}
                >
                  Price:{" "}
                </span>
                <span
                  style={{
                    color: "#8A1A75",
                    fontWeight: "600",
                    fontFamily: "Fira Sans",
                  }}
                >
                  £{subtotal}
                </span>
                
          
              </div>
              
            </Row>
          </ModalBody>
          {isEdit ? (<ModalFooter style={{ display: "flex" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "700",
                fontFamily: "Fira Sans",
              }}
            >
              <Btn
                attrBtn={{
                  type: "reset",
                  color: "",
                  id: id,
                  onClick: this.onDeleteConfirm,
                  style: {
                    backgroundColor: "#e74c3c",
                    color: "#FFF",
                  },
                }}
              >
                {isDeleteConfirm ? "Are you sure?" : "Delete"}
              </Btn>

              <div>
                <Btn
                  attrBtn={{
                    type: "reset",
                    color: "",
                    onClick: this.onCancelConfirm,
                    style: {
                      backgroundColor: "#450d3a",
                      color: "#FFF",
                    },
                  }}
                >
                  {isCancelConfirm ? "Are you sure?" : "Cancel"}
                </Btn>
                <Btn
                  attrBtn={{
                   
                    color: "primary",
                    type: "submit",
                    
                    style: { marginLeft: "20px" },
                  }}
                >
                  Update Additional Book
                </Btn>
              </div>
            </div>
          </ModalFooter>) : (<ModalFooter
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "700",
                fontFamily: "Fira Sans",
              }}
            >
              <Btn attrBtn={{
                type: "reset",
                color: "",
                onClick: this.onCancelConfirm,
                style: {
                  backgroundColor: "#450d3a",
                  color: "#FFF",
                },
              }} >
                {isCancelConfirm ? "Are you sure?" : "Cancel"}
              </Btn>
              &nbsp;&nbsp;
              <Btn attrBtn={{ color: "primary", type: "submit" }}>
                Add Additional Book
              </Btn>
            </div>
          </ModalFooter>)}
        </Form>
      </Modal>
    )
   
  }
}

const mapStateToProps = (state) => { };

const mapDispatchToProps = {
  addAdditionalOrderBook: myStoryOperations.addAdditionalOrderBook,
  updateAdditionalOrderBook: myStoryOperations.updateAdditionalOrderBook,
  deleteAdditionalOrderBook: myStoryOperations.deleteAdditionalOrderBook,

  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAdditionalOrderBooks);
