import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const listReducer = createReducer([])({
  
    [types.UPDATE_EMAIL_FREQUENCY_COMPLETED]: (state, action) => {
        const frequency = action.payload.payload
        return state.map(e => e.id === frequency.id ? frequency : e)
    },
    
});

export default combineReducers({
    list: listReducer
});