export const LOGIN = "auth/LOGIN";
export const LOGIN_COMPLETED = "auth/LOGIN_COMPLETED";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const LOGOUT = "auth/LOGOUT";
export const AUTHENTICATED = "auth/AUTHENTICATED";
export const INITIALIZE = "auth/INITIALIZE_SESSION";
export const DESTROY = "auth/DESTROY_SESSION";
export const FETCH_MENU_LIST = "auth/FETCH_MENU_LIST";
export const FETCH_MENU_LIST_COMPLETED = "auth/FETCH_MENU_LIST_COMPLETED";
export const FETCH_MENU_LIST_FAILED = "auth/FETCH_MENU_LIST_FAILED";
export const AUTHORIZATION_FAILED = "auth/AUTHORIZATION_FAILED";
export const SET_REDIRECT_AFTER_LOGIN = "auth/SET_REDIRECT_AFTER_LOGIN";

export const CHANGE_PASSWORD = "auth/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_COMPLETED = "auth/CHANGE_PASSWORD_COMPLETED";
export const CHANGE_PASSWORD_FAILED = "auth/CHANGE_PASSWORD_FAILED";

export const RESET_PASSWORD = "auth/RESET_PASSWORD";
export const RESET_PASSWORD_COMPLETED = "auth/RESET_PASSWORD_COMPLETED";
export const RESET_PASSWORD_FAILED = "auth/RESET_PASSWORD_FAILED";