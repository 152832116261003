import React, { Fragment } from 'react';
import { Container, Card, CardBody, Col, Form, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class QuestionsSendThankYou extends React.Component {

  render() {
    return (
      <Fragment>
        <div className='page-title'></div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <h1 style={{ textAlign: "center", marginTop: "2%", fontFamily: "Martel" }}>Your questions have been sent!</h1>
                <span style={{ borderBottom: "2px solid #FEC1EB", height: "2px", width: "102px", margin: "10px auto" }}></span>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='1'></Col>
                      <Col md='10'>
                        <div style={{ fontSize: "17.6px" }}>Your story teller will receive an email notification and your question has been added to their story.</div>
                        <div className='mt-3' style={{ fontSize: "17.6px" }}>Sending specific questions is a great way to keep your storyteller motivated. Encourage them to keep writing so that their memories and life stories can be shared for generations to come.</div>
                      </Col>
                      <Col md='1'></Col>
                    </Row>
                    <Row className='mt-1 mb-5'></Row>
                    <Row className='mt-1 mb-5'></Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = () => { return {} };

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsSendThankYou);